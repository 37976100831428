import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import Arrow from "../icons/arrow";
import BreakdownContainer from "./breakdown_container";
import TeamCapacity from "./team_capacity";
import IndividualCapacity from "./individual_capacity";
import axios from "axios";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown";
import DropdownMenu from "../common/DropdownMenu";
import DateRangeArrow from "../icons/daterange_arrow";
import Chart from "chart.js/auto";
import { CSVLink } from "react-csv";

const ChartContainer = ({ teamFilters }) => {
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("week").add(1, "day")
  );
  const [endDate, setEndDate] = useState(moment(new Date()));
  const [prevStartDate, setPrevStartDate] = useState(startDate);
  const [prevEndDate, setPrevEndDate] = useState(endDate);
  const [error, setError] = useState("");
  const [chartData, setChartData] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [initiateDownload, setInitiateDownload] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const csvLink = useRef();

  const handleDateChange = (start, end, value = sliderValue, team = null) => {
    setLoading(true);
    const maxDays = 60;
    const formattedStartDate = start ? start.format("YYYY-MM-DD") : null;
    const formattedEndDate = end.format("YYYY-MM-DD");

    if (start.isSame(end)) {
      end.add(0, "day");
    }
    const diffDays = end.diff(start, "days");

    if (diffDays > maxDays) {
      setError(`The selected date range cannot exceed ${maxDays} days.`);

      setEndDate(moment(start).add(maxDays, "days"));
    } else {
      setError("");
      axios
        .get(`/capacity_dashboard/${formattedStartDate}/${formattedEndDate}`, {
          params: { user_filter: value, team: team?.label },
        })
        .then((res) => {
          setChartData(res.data.chart_data);
          setDoughnutData(res.data.doughnut_data);
          setTeamData(res.data.team_data);
          setUserList(res.data.user_list);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
    setPrevStartDate(start);
    setPrevEndDate(end);
    setShowSubmitButton(false);
  };
  const bookedVacationUnavailablePercentage = (data = [], totalHours = 0) => {
    const [booked = 0, vacation = 0, unavailable = 0] = data || [];
    if (totalHours === 0) return 0;
    return Math.round(((booked + vacation + unavailable) / totalHours) * 100);
  };

  useEffect(() => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    axios
      .get(`/capacity_dashboard/${formattedStartDate}/${formattedEndDate}`, {})
      .then((res) => {
        setChartData(res.data.chart_data);
        setDoughnutData(res.data.doughnut_data);
        setTeamData(res.data.team_data);
        setUserList(res.data.user_list);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // This updates csvData but doesn't trigger download immediately
  useEffect(() => {
    if (initiateDownload) {
      updateCsvData();
    }
  }, [initiateDownload]);

  // Now we trigger download only after csvData is updated
  useEffect(() => {
    if (csvData.length > 0 && initiateDownload) {
      csvLink.current.link.click();
      setInitiateDownload(false);
    }
  }, [csvData]);

  const updateCsvData = () => {
    const sliderLabel =
      sliderValue === 0 ? "All" : sliderValue === 1 ? "PT" : "FT";
    const presentPercentage = bookedVacationUnavailablePercentage(
      doughnutData.present_data_hours || [0, 0, 0, 0],
      doughnutData.present_total_hours || 0
    );

    const historicalPercentage = bookedVacationUnavailablePercentage(
      doughnutData.historical_data || [0, 0, 0, 0],
      doughnutData.historical_total_hours || 0
    );

    const processedData = chartData.datasets.map((item) => ({
      Date: item.label,
      team: selectedOption?.label || "All",
      capacityPercentage: `${item.total_day_data}%`,
      historicalPercentage: `-`,
      currentTotalHours: `-`,
      historicalTotalHours: `-`,
      status: sliderLabel,
    }));

    const summaryRow = {
      Date: "Overall Summary",
      team: selectedOption?.label || "All",
      capacityPercentage: `${presentPercentage}%`,
      historicalPercentage: `${historicalPercentage}%`,
      currentTotalHours: `${doughnutData.present_total_hours}`,
      historicalTotalHours: `${doughnutData.historical_total_hours}`,
      status: sliderLabel,
    };

    setCsvData([
      ...processedData,
      summaryRow,
      {},
      {
        Date: "Name",
        team: "Available %",
        capacityPercentage: "Booked %",
      },
      ...userList.map((user) => ({
        Date: user.name,
        team: `${user.availablePercentage}%`,
        capacityPercentage: `${user.bookedPercentage}%`,
      })),
    ]);
  };

  const isDayBlocked = (day) => {
    const today = moment().startOf("day");

    // Block weekends
    const dayOfWeek = moment(day).day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return true;
    }

    // Block dates in the future (after today)
    // if (moment(day).isAfter(today)) {
    //   return true;
    // }

    // Block days more than 60 days from the start date
    if (startDate) {
      const maxDateRange = moment(startDate).add(60, "days");
      return moment(day).isAfter(maxDateRange);
    }

    // If no start date is selected, don't block any days
    return false;
  };

  const handleSliderChange = (event) => {
    const value = event.target.value;
    setSliderValue(value);

    handleDateChange(prevStartDate, prevEndDate, value, selectedOption);
  };

  const handleArrowClick = (isPrev = false) => {
    let newStartDate;
    let newEndDate;
    const diffDays = endDate.diff(startDate, "days");

    if (isPrev) {
      newStartDate = startDate.subtract(diffDays === 0 ? 1 : diffDays, "days");
      newEndDate = endDate.subtract(diffDays === 0 ? 1 : diffDays, "days");
    } else {
      newStartDate = startDate.add(diffDays === 0 ? 1 : diffDays, "days");
      newEndDate = endDate.add(diffDays === 0 ? 1 : diffDays, "days");
    }

    handleDateChange(newStartDate, newEndDate, sliderValue, selectedOption);
  };

  const handleOptionChange = (event) => {
    console.log(event);
    setSelectedOption(event);
    handleDateChange(prevStartDate, prevEndDate, sliderValue, event);
  };

  return (
    <CapacityContainer>
      {loading && <Loader />}
      <CapacityWrapper
        style={loading ? { pointerEvents: "none", opacity: 0.2 } : {}}
      >
        <CapacityNavigation>
          <TitleContainer>
            <Logo>
              <svg
                width="28"
                height="29"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 15.3235H13.1765V28.5H0V15.3235ZM14.8235 15.3235H28V28.5H14.8235V15.3235ZM0 0.5H13.1765V13.6765H0V0.5ZM18.1177 0.5H28V10.3823H18.1177V0.5Z"
                  fill="#519ACC"
                />
              </svg>
            </Logo>
            <TitleName className="breakdown_title">Capacity</TitleName>
            <MonthSelect>
              <ArrowContainer>
                <DateRangeArrow />
              </ArrowContainer>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDatesChange={({ startDate, endDate }) => {
                  setShowSubmitButton(true);

                  if (startDate && endDate) {
                    if (endDate.isBefore(startDate)) {
                      [startDate, endDate] = [endDate, startDate];
                    }

                    const maxEndDate = moment(startDate).add(60, "days");
                    if (moment(endDate).isAfter(maxEndDate)) {
                      endDate = maxEndDate;
                    }
                  }

                  setStartDate(startDate);
                  setEndDate(endDate);
                  document.querySelector(".breakdown_title").click();
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                orientation="horizontal"
                numberOfMonths={1}
                autocomplete="on"
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div
                    style={{
                      right: "84px !important",
                      position: "absolute",
                      top: "16px",
                      right: "87px",
                      padding: "6px 9px",
                      lineHeight: " .78",
                      borderRadius: "3px",
                    }}
                  >
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown />}
                isOutsideRange={isDayBlocked}
                enableOutsideDays={true}
                minimumNights={-1}
                readOnly
              />

              <SubmitButton
                type="submit"
                onClick={() => {
                  handleDateChange(
                    startDate,
                    endDate,
                    sliderValue,
                    selectedOption
                  );
                  document.querySelector(".breakdown_title").click();
                }}
                style={{ display: showSubmitButton ? "block" : "none" }}
              >
                Submit
              </SubmitButton>
            </MonthSelect>
            <ArrowSelect>
              <ArrowWrapper
                loading={loading}
                onClick={() => handleArrowClick(true)}
              >
                <Arrow />
              </ArrowWrapper>
              <ArrowWrapper
                loading={loading}
                onClick={() => handleArrowClick(false)}
              >
                <Arrow rotate="right" />
              </ArrowWrapper>
            </ArrowSelect>
          </TitleContainer>
          <TeamContainer>
            <SliderContainer>
              <CustomSlider
                id="myinput"
                type="range"
                min="0"
                max="2"
                step="1"
                onChange={handleSliderChange}
                defaultValue={sliderValue}
                disabled={loading}
              />
              <SliderLabels>
                <span>All</span>
                <span style={{ marginLeft: "20px" }}>Part-Time</span>
                <span>Full-Time</span>
              </SliderLabels>
            </SliderContainer>

            <TeamSelect>
              <DropdownMenu
                selectedOption={selectedOption}
                onOptionChange={handleOptionChange}
                options={teamFilters}
              />
            </TeamSelect>
            <ExportData onClick={() => setInitiateDownload(true)}>
              Export Data
              {initiateDownload && (
                <CSVLink
                  data={csvData}
                  target="_blank"
                  filename={`capacity_data_${startDate.format(
                    "YYYY-MM-DD"
                  )}_to_${endDate.format("YYYY-MM-DD")}.csv`}
                  ref={csvLink}
                />
              )}
            </ExportData>
          </TeamContainer>
        </CapacityNavigation>
        <DataWrapper>
          <BreakdownContainer
            chartData={chartData}
            doughnutData={doughnutData}
            startDate={prevStartDate}
            endDate={prevEndDate}
          />
          <UsersContainer>
            {(selectedOption === null || selectedOption.label === "All") && (
              <TeamCapacity teamData={teamData} />
            )}

            <IndividualCapacity users={userList} />
          </UsersContainer>
        </DataWrapper>
      </CapacityWrapper>
    </CapacityContainer>
  );
};

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const SliderLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 159px;
  font-size: 11px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  color: #242424;

  span {
  }
`;

const Loader = styled.span`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: fixed;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 20px;
  opacity: 0.7;
  animation: rotate 1s linear infinite;
  z-index: 100000;

  :before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #578bc2;
    animation: prixClipFix 2s linear infinite;
  }
`;

const SubmitButton = styled.button`
  display: inline-block;
  width: auto;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #519acc;
  box-shadow: 0px 3px 0px 0px #578bc2;
  height: 40px;
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  color: white;
  border: none;
  white-space: nowrap;
  cursor: pointer;
`;

const CustomSlider = styled.input`
  -webkit-appearance: none; /* Remove default slider styles */
  width: 100%;
  height: 8px;
  border-radius: 10px;
  outline: none;
  transition: background 0.3s ease-in-out;
  background: linear-gradient(
    to right,
    #519acc 0%,
    #519acc 0%,
    #fff 0%,
    white 100%
  );

  /* Styling for when hovering or clicking the thumb */
  &:hover::-webkit-slider-thumb {
    background-color: #519acc; /* Lightened blue */
  }

  &:hover::-moz-range-thumb {
    background-color: #519acc; /* Lightened blue */
  }
`;
const CapacityContainer = styled.div`
  background-color: rgb(242, 242, 242);
  overflow-x: auto;
  padding: 16px;
  min-height: calc(-68px + 100vh);
  overflow: hidden;
`;

const CapacityWrapper = styled.div`
  margin: 0px 0px 8px;
  border-radius: 10px;
  border: 1px solid rgb(215, 215, 215);
  background-color: white;
  overflow: visible;
  position: relative;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DataWrapper = styled.div`
  width: 100%;
  padding: 32px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
const CapacityNavigation = styled.div`
  width: 100%;
  height: 77px;
  padding: 16px 32px 16px 32px;
  gap: 0px;
  border-radius: 10px 10px 0px 0px;
  border: 0px 0px 1px 0px;
  justify: space-between;
  opacity: 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
`;

const TitleContainer = styled.div`
  width: Hug (381px) px;
  height: Hug (39px) px;
  gap: 16px;
  opacity: 0px;
  display: flex;
  align-items: center;
`;

const TeamContainer = styled.div`
  width: Hug (445.08px) px;
  height: Hug (45px) px;
  gap: 16px;
  opacity: 0px;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  width: 28px;
  height: 28px;
  gap: 0px;
  opacity: 0px;
`;

const TitleName = styled.div`
  width: 87px;
  height: 27px;
  gap: 0px;
  opacity: 0px;
  //styleName: H2 - Manrope - Bold - 20;
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const MonthSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: relative;
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 12px;
  left 86px
`;

const ArrowSelect = styled.div`
  display: flex;
  gap: 4px;
  position: relative;
  left: -8px;
`;

const TeamSlider = styled.div``;

const TeamSelect = styled.div``;

const ExportData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 45px;
  padding: 12px 16px 12px 16px;
  gap: 0px;
  border-radius: 5px;
  justify: space-between;
  opacity: 0px;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;

  :hover {
    border: 1px solid #519acc;
  }
`;

const ArrowWrapper = styled.div`
  width: 28px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 5px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  opacity: ${({ loading }) => (loading ? "0.5" : "1")};

  :hover {
    border: 1px solid #519acc;
  }
`;

const UsersContainer = styled.div`
  width: 100%;
  height: 536px;
  display: flex;
  justify-content: center;
  gap: 8px;
`;
export default ChartContainer;
